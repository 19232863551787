'use client'

import { AdUnitPlaceholder, AdUnitPlaceholderProps } from '@cheddartv/storybook-news'

import { memo } from 'react'

import adConfig, { Tag, TAdConfigType } from '../../utils/adConfig'
import { useEnvironmentContext } from '../EnvironmentProvider'
import { StyledAdCustomContainer, StyledTaboolaContainer } from './Ads.styles'

import dynamic from 'next/dynamic'
import Script from 'next/script'

const GAMAdBase = dynamic(() => import('./GAMAdBase'), { ssr: false })

const CHEDDAR_TABOOLA_SCRIPT = `
  window._taboola = window._taboola || [];
  _taboola.push({article:'auto'});
  !function (e, f, u, i) {
    if (!document.getElementById(i)){
      e.async = 1;
      e.src = u;
      e.id = i;
      f.parentNode.insertBefore(e, f);
    } }(document.createElement('script'),
  document.getElementsByTagName('script')[0],
  '//cdn.taboola.com/libtrc/cheddar-network/loader.js',
  'tb_loader_script');
  if(window.performance && typeof window.performance.mark == 'function')
    {window.performance.mark('tbl_ic');}
`

const NEWS12_TABOOLA_SCRIPT = `
  window._taboola = window._taboola || [];
  _taboola.push({article:'auto'});
  !function (e, f, u, i) {
    if (!document.getElementById(i)){
      e.async = 1;
      e.src = u;
      e.id = i;
      f.parentNode.insertBefore(e, f);
    } }(document.createElement('script'),
  document.getElementsByTagName('script')[0],
  '//cdn.taboola.com/libtrc/news12optimum-network/loader.js',
  'tb_loader_script');
  if(window.performance && typeof window.performance.mark == 'function')
    {window.performance.mark('tbl_ic');}
`

type Config = {
  mode?: string
  container?: string
  placement?: string
  target_type?: string
}

type AdType = TAdConfigType | 'taboola'

type AdParams = {
  type: AdType
  id: string
  template?: string
  config: Config
  tags?: Tag[]
  contentfulCategories?: string[]
  adUnitTemplate?: AdUnitPlaceholderProps['template']
  adUnitSizes?: AdUnitPlaceholderProps['sizes']
}

const renderAd = ({
  adUnitTemplate,
  adUnitSizes,
  type,
  id,
  template,
  config,
  tags,
  contentfulCategories
}: AdParams) => {
  if (type === 'taboola') {
    return (
      <StyledTaboolaContainer data-testid={`${type}-ad`} id={config.container}>
        <Script
          dangerouslySetInnerHTML={{
            __html: `
          window._taboola = window._taboola || [];
          _taboola.push(${JSON.stringify(config)});
          _taboola.push({flush: true});
        `
          }}
        />
      </StyledTaboolaContainer>
    )
  }

  if (type === 'custom') {
    return (
      <StyledAdCustomContainer data-testid={`${type}-ad`}>
        <GAMAdBase config={{ ...adConfig(type, template, tags, contentfulCategories), id }} />
      </StyledAdCustomContainer>
    )
  }

  return (
    <AdUnitPlaceholder template={adUnitTemplate} sizes={adUnitSizes}>
      <GAMAdBase config={{ ...adConfig(type, template, tags, contentfulCategories), id }} />
    </AdUnitPlaceholder>
  )
}

type AdUnitProps = {
  id: string
  tags?: Tag[]
  contentfulCategories?: string[]
  type: AdType
  adConfig?: {
    mode: string
    container: string
    placement: string
    target_type: string
  }
  adUnitTemplate?: AdUnitPlaceholderProps['template']
  adUnitSizes?: AdUnitPlaceholderProps['sizes']
}

const AdUnit = memo(({ id, type, adConfig, adUnitTemplate, adUnitSizes, tags, contentfulCategories }: AdUnitProps) => {
  const { ffNewTaboolaLoader } = useEnvironmentContext()
  const TABOOLA_SCRIPT = ffNewTaboolaLoader ? NEWS12_TABOOLA_SCRIPT : CHEDDAR_TABOOLA_SCRIPT

  const adParams = {
    adUnitTemplate,
    adUnitSizes,
    contentfulCategories,
    type,
    id,
    tags,
    config: adConfig ?? {},
    template: 'default'
  }

  return (
    <>
      {type === 'taboola' && (
        <Script dangerouslySetInnerHTML={{ __html: TABOOLA_SCRIPT }} strategy='beforeInteractive' />
      )}
      {renderAd(adParams)}
    </>
  )
})

export default AdUnit
