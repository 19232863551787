import React, { useContext } from 'react'

export function loadEnvironmentVariables(): EnvironmentContextValues {
  return {
    ffNewTaboolaLoader: process.env.FF_NEW_TABOOLA_LOADER === 'true'
  }
}

export type EnvironmentContextValues = {
  ffNewTaboolaLoader: boolean
}

export const EnvironmentContext = React.createContext<EnvironmentContextValues>({
  ffNewTaboolaLoader: true
})

export const useEnvironmentContext = () => {
  return useContext(EnvironmentContext)
}

const EnvironmentProvider = ({
  children,
  environmentVariables
}: {
  children: React.ReactNode
  environmentVariables: EnvironmentContextValues
}) => {
  const value: EnvironmentContextValues = {
    ffNewTaboolaLoader: environmentVariables.ffNewTaboolaLoader
  }

  return <EnvironmentContext.Provider value={value}>{children}</EnvironmentContext.Provider>
}

export default EnvironmentProvider
